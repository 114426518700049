<section class="container mt-5">
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <h5>{{ title }}</h5>
    </div>
    <div class="modal-body">
      @if(listaClientes?.length > 0){
      <div class="table-responsive">
        <table class="table table-hover table-bordered mb-0 bg-secondary">
          <thead>
            <tr class="text-center" align="center">
              <th>Nome</th>
              <th>Telefone</th>
              <th>Email</th>
              <th>Tipo de Avaliação</th>
              <th>Periodo Restante</th>
            </tr>
          </thead>
          <tbody>
            @for (cliente of listaClientes; track $index) {
            <tr class="text-center">
              <td>{{cliente.account.nome}}</td>
              <td class="tdwrap">{{cliente.account!.celular | phone }}</td>
              <td>{{cliente.account.email}}</td>
              <td>{{definindoTipoAvaliacao(cliente)}}</td>
              <td>{{cliente.avaliacao.periodoRestante}}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      }@else {
      <div class="d-flex bg-white justify-content-center">
        <h5 class='text-muted p-3'>
          Não foi localizado usuários em avaliação, na base de dados
        </h5>
      </div>
      }
    </div>
  </div>
</section>
