import { Component, Input, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';
import { FormEmissaoNfComponent } from './components/form-emissao-nf/form-emissao-nf.component';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule, NgbAccordionModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { ViaCepService } from 'src/app/shared/services/via-cep.service';
import { EnderecoV1Model } from 'src/app/auth/models/account-v1.model';

@Component({
  selector: 'app-form-assinatura',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormEmissaoNfComponent,
    NgbNavModule,
    NgbTooltipModule,
    FeatherModule,
    NgbAccordionModule
  ],
  templateUrl: './form-assinatura.component.html',
  styles: ``
})
export class FormAssinaturaComponent {
  //variáveis de ambiente
  formDadosAssinatura: FormGroup;
  isCepValido: boolean = false;
  isUtilizarMesmoEmail: boolean = false;
  isUtilizarMesmoNome: boolean = false;
  isUtilizarMesmoCPF: boolean = false;
  isUtilizarMesmoCelular: boolean = false;
  @Input() emailUsuario?: string;
  @Input() nomeUsuario?: string;
  @Input() telefoneUsuario?: string;
  @Input() cpfUsuario?: string;
  labelNome: string = 'Titular do Cartão:';
  isEmitirNf: boolean = false;
  helpLink: string = 'https://doc.extratoofx.com/docs/tutorial-utilizando/planos#cupom-de-desconto'
  isGerarLinkPagamento: boolean = false;

  static dadosAssinatura = new Subject<DadosAssinaturaV1Model>();

  constructor(
    private fb: FormBuilder,
    public formReativo: FormReativoService,
    public masksUtils: MasksUtil,
    private viaCepService: ViaCepService,
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) {
    this.formDadosAssinatura = this.fb.group(this.montarFormDadosAssinatura());
  }

  montarFormDadosAssinatura() {
    return {
      nomeTitular: [
        '',
        Validators.required
      ],
      cpfCnpjTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
        ])
      ],
      emailTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ],
      celular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ])
      ],
      numeroCartao: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16)
        ])
      ],
      cvv: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(3)
        ])
      ],
      validade: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6)
        ])
      ],
      cepTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/\d+/)
        ])
      ],
      logradouro: '',
      numeroEndereco: [
        '',
        Validators.required,
      ],
      bairro: '',
      cidade: '',
      estado: '',
    }
  }

  get controls() {
    return this.formDadosAssinatura.controls;
  }

  /**
   * Manipula a alteração de gerar link de pagamento.
   *
   * Esta função é executada quando há uma alteração na opção de gerar link de pagamento.
   * Remove ou adiciona os controles do formulário relacionados ao cartão de crédito
   * e atualiza o label do nome de acordo. 
   * Emite os dados do cartão após as alterações.
   *
   * @method
   */
  onChangeGerarLinkPagamento() {
    if (this.isGerarLinkPagamento!) {
      this.formReativo.removeControlsFromForm(
        this.formDadosAssinatura,
        'cvv',
        'numeroCartao',
        'validade',
        'cepTitular',
        'logradouro',
        'numeroEndereco',
        'bairro',
        'cidade',
        'estado'
      )
      this.labelNome = 'Nome Completo:'
    } else {
      this.labelNome = 'Titular do Cartão:'
      this.formReativo.addControlsToForm(
        this.formDadosAssinatura,
        '',
        [Validators.required],
        'cvv', 'numeroCartao', 'validade', 'cepTitular', 'numeroEndereco'
      );
      this.formReativo.addControlsToForm(
        this.formDadosAssinatura,
        '',
        [],
        'bairro', 'cidade', 'estado', 'logradouro'
      );
    }

    this.emitirDadosAssinatura();
  }

  emitirDadosAssinatura() {
    if (this.formDadosAssinatura.valid!) {
      if (this.isGerarLinkPagamento!) {
        const dadosAEmitir: DadosAssinaturaV1Model = {
          nomeTitular: this.controls['nomeTitular'].value,
          celular: this.controls['celular'].value,
          cpfCnpj: this.controls['cpfCnpjTitular'].value,
          emailTitular: this.controls['emailTitular'].value,
          isLinkPagamento: this.isGerarLinkPagamento,
          cvv: undefined,
          numeroCartao: undefined,
          validade: undefined,
          bairro: '',
          cidade: '',
          logradouro: '',
          numero: '',
          uf: '',
          cep: '',
        }
        FormAssinaturaComponent.dadosAssinatura.next(dadosAEmitir);
      } else {
        if (this.verificarValidadeInformada(this.controls['validade'].value)) {
          const dadosAEmitir = {
            nomeTitular: this.controls['nomeTitular'].value,
            celular: this.controls['celular'].value,
            cpfCnpj: this.controls['cpfCnpjTitular'].value,
            emailTitular: this.controls['emailTitular'].value,
            isLinkPagamento: this.isGerarLinkPagamento,
            cvv: this.controls['cvv'].value,
            numeroCartao: this.controls['numeroCartao'].value,
            validade: this.controls['validade'].value,
            bairro: this.controls['bairro'].value,
            cidade: this.controls['cidade'].value,
            logradouro: this.controls['logradouro'].value,
            numero: this.controls['numeroEndereco'].value,
            uf: this.controls['estado'].value,
            cep: this.controls['cepTitular'].value,
          }
          FormAssinaturaComponent.dadosAssinatura.next(dadosAEmitir);
        } else {
          this.controls['validade'].setErrors({ notEqual: true });
        }
      }
    } else {
      FormAssinaturaComponent.dadosAssinatura.next({ isLinkPagamento: this.isGerarLinkPagamento });
    }
  }

  verificarValidadeInformada(validade: string) {
    const mes = validade.slice(0, 2);
    const ano = validade.slice(2);

    const dataInformada = new Date(parseInt(ano), (parseInt(mes) - 1));
    const dataAtual = new Date();

    if (dataInformada.toISOString() > dataAtual.toISOString()) {
      /**Se a data informada for maior que a data de hoje */
      return true
    } else {
      return false
    }
  }

  emitirNF() {
    this.isEmitirNf = !this.isEmitirNf;
    if (!this.isEmitirNf) {
      FormEmissaoNfComponent.dadosEmissaoNF.next(undefined);
    }
  }

  /**
  * Função acionada quando o input do cep for alterado
  */
  async aoInformarOCep() {
    /**
     * Se o control cep estiver válido (com o min e o max length atingido),
     * o if é atendido e acessado
     */
    if (this.controls['cepTitular'].valid!) {
      const cep = this.controls['cepTitular'].value;
      this.loaderService.startLoader(false);
      await this.viaCepService.buscarEnderecoPorCep(cep)
        .subscribe({
          next: data => {
            if (!data.erro) {
              this.formDadosAssinatura.patchValue({
                logradouro: data.logradouro,
                bairro: data.bairro,
                cidade: data.localidade,
                estado: data.uf
              });

              this.controls['bairro'].disable();
              this.controls['cidade'].disable();
              this.controls['estado'].disable();

              // endereco = `${data.logradouro} - ${data.bairro} - ${data.localidade}/${data.uf} - ${data.cep}`;
              this.isCepValido = true;
              this.loaderService.stopLoader();
            } else {
              this.isCepValido = false;
              this.loaderService.stopLoader();
            }
          },
          error: () => {
            this.loaderService.stopLoader();
            this.controls['cepTitular'].setErrors([{ pattern: true }]);
          }
        });
    }
  }

  preencherFormComDadosDoUsuario() {
    this.formDadosAssinatura.patchValue({
      'nomeTitular': this.nomeUsuario,
      'emailTitular': this.emailUsuario,
      'celular': this.telefoneUsuario,
      'cpfCnpjTitular': this.cpfUsuario
    });

    if (!this.cpfUsuario) {
      this.renderer.selectRootElement('#cpfCnpjTitular')?.focus();
    } else if (!this.telefoneUsuario) {
      this.renderer.selectRootElement('#celular')?.focus();
    }
  }

  proximoInput(formControl: string, maxLength: number, proxInput: string) {
    if (this.controls[formControl]?.value.length == maxLength) {
      this.renderer.selectRootElement(`#${proxInput}`)?.focus();
    }
  };
}

interface DadosAssinaturaV1Model extends EnderecoV1Model {
  nomeTitular?: string;
  cpfCnpj?: string;
  celular?: string;
  emailTitular?: string;
  numeroCartao?: string;
  cvv?: string;
  validade?: string;
  cpfUsuario?: string;
  isLinkPagamento?: boolean;
}